<template>
    <div id="success-payment-page">
        <NavPackage/>
        <div class="justify-content-center align-items-center d-flex flex-column mt-100">
            <img src="@/assets/images/success-payment.svg" class="mb-4" style="height: 128px; width: 128px" alt="">
            <span class="title-sp-payment mb-4">Payment successfull</span>
            <span class="sub-title-sp-payment">Your payment has been successfully received.</span>
            <span class="sub-title-sp-payment mb-4">A copy of your receipt will be sent to your email.</span>
            <span @click="goToSetting()" class="btn-sp">Go to Homepage</span>
        </div>
    </div>
</template>

<script>
import NavPackage from '../../../components/nav-packages.vue'
export default {
  name: 'PaymentSuccess',
  components: {
      NavPackage
  },
  mounted() {
    //   this.$ga.page('payment/success')
      this.$ga.event('payment-success-page', 'actionx', 'labelx', 1)
  },
  methods: {
    goToSetting(){
        this.$store.commit('state/SET_STATE', 'storage')
        this.$router.push('/settings/plan')
    }
  }
}
</script>

<style scoped>

</style>